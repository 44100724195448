window.jQuery = window.$ = require("jquery");
require('owl.carousel/src/js/owl.carousel');
const $C = (el) => document.querySelector(el);

/**
 * Add select_item event to GTM dataLayer
 */
let productTiles = document.querySelectorAll('#product-search-results .product');
window.dataLayer = window.dataLayer || [];

function setEventListener(e) {
  let gtmProdData = this.getAttribute('data-gtmga4data');
  let gtmProdDataObj = JSON.parse(gtmProdData);
  let event = "select_item";
  let gtmData = {
    "event": event,
    "ecommerce": {
      "cart_id": gtmProdDataObj.cart_id,
      "date_created_cart": gtmProdDataObj.date_created_cart,
      "date_update_cart": gtmProdDataObj.date_update_cart,
      "total_quantity_cart": gtmProdDataObj.total_quantity_cart,
      "total_price_cart": gtmProdDataObj.total_price_cart,
      "currencyCode": gtmProdDataObj.currencyCode,
      "items": [{
        "item_id": gtmProdDataObj.item_id,
        "item_name": gtmProdDataObj.item_name,
        "item_variant": gtmProdDataObj.item_variant,
        "price_without_discount": gtmProdDataObj.price_without_discount,
        "is_in_stock": gtmProdDataObj.is_in_stock,
        "is_on_sale": gtmProdDataObj.is_on_sale,
        "item_list_name": gtmProdDataObj.item_list_name,
        "discount": gtmProdDataObj.discount,
        "item_brand": gtmProdDataObj.item_brand,
        "size": gtmProdDataObj.size,
        "quantity": gtmProdDataObj.quantity,
        "added_date": gtmProdDataObj.added_date,
        "item_list_id": gtmProdDataObj.item_list_id,
        "image": gtmProdDataObj.image,
        "item_category": gtmProdDataObj.item_category,
        "price": gtmProdDataObj.price,
        "type": gtmProdDataObj.type,
        // "index": index + 1
      }]
    }
  };
  window.dataLayer.push(gtmData);
}

window.onload = function () {
  productTiles?.forEach(function (productTile) {
    productTile.addEventListener('click', function (e) {
      setEventListener.call(productTile, e);
    });
  });
};

/**
 * End select_item event to GTM dataLayer
 */

/* PLP */
// SHOW MORE BUTTON

var plpItems = document.querySelectorAll('#product-search-results .product');
plpItems.forEach(function (item) {
  item.addEventListener('click', function (e) {
    sessionStorage.setItem('fromPLP', true);
  });
});
let showMoreButton = $C(".grid-footer .show-more button");

let changeSize = false;
if (showMoreButton) {
  window.addEventListener("scroll", () => {
    if (
      window.scrollY >=
      document.body.clientHeight - document.body.clientHeight * 0.6 &&
      !changeSize
    ) {
      if (showMoreButton) showMoreButton.click();
      changeSize = true;
      setTimeout(() => {
        productTiles = document.querySelectorAll('.product-grid .product');
        productTiles.forEach(function (item) {
          item.removeEventListener('click', setEventListener);
          item.addEventListener('click', setEventListener);
        });
        changeSize = false;
        showMoreButton = $C(".grid-footer .show-more button");
        // console.log(showMoreButton);
      }, 1000);
    }
  });
}


const productId = document.querySelector(".productID .productID__id");

if (productId) {
  $('.select-talla').on("click", () => {
    if ($('li.select-colors').hasClass('actives')) {
      setTimeout(() => {
        const productId = document.querySelector(
          ".productID .productID__id"
        );

        const SKU = document.querySelector(".productSku .product-id");
        if (productId.innerText != SKU.innerText) {
          $(".productSku").removeClass("d-none");
        }
      }, 500);

      $('.list-talla').on("click", () => {
        setTimeout(() => {
          const productId = document.querySelector(
            ".productID .productID__id"
          );
          const SKU = document.querySelector(".productSku .product-id");

          if (productId.innerText != SKU.innerText) {
            $(".productSku").removeClass("d-none");
          }
        }, 500);
      });
    }
  })
}
//************************************************ */
document.addEventListener("DOMContentLoaded", function (event) {
  var priceTotalValue = $('#cart-price-total').val();
  if (priceTotalValue) {
    var priceWithoutDollar = priceTotalValue.replace(',', '').replace('$', '')
    var priceAsInteger = parseInt(priceWithoutDollar).toFixed(); // Assuming the price is in dollars and cents
    if (priceAsInteger < 300.00) {
      $('#paymentType').find("option:contains('3')").hide();
      $('#paymentType').find("option:contains('6')").hide();
      $('#paymentType').find("option:contains('9')").hide();
    } else if (priceAsInteger >= 300.00 && priceAsInteger < 600.00) {
      $('#paymentType').find("option:contains('6')").hide();
      $('#paymentType').find("option:contains('9')").hide();
    } else if (priceAsInteger >= 600.00 && priceAsInteger < 900.00) {
      $('#paymentType').find("option:contains('9')").hide();
    }
  }
});

//************************************************ */
  /**MEGA MENU HIDE / SHOW SECTION */
  $('.display-menu-data').click(function(){
      let category = $(this).attr('data-id');
      let subid = $(this).attr('data-subid');
      let closeid = $(this).attr('data-close-id');
      let i= 0;
        let height= $('.'+category+' div .'+category+'-hide').height();
      if(closeid=='0'){
        $('.'+category+' div .'+category+'-hide').hide();
        $('.'+subid+'-'+category+'-sub-category').addClass('display-flex');
        i++;
          $("."+category).css({'min-height':height+30});
      }
      else{
        $('.'+closeid).removeClass('display-flex');
        $('.'+category+' div .'+category+'-hide').show();
        $("."+category).removecss({'height':''});
      }
      return false;
  });
  $('.level-one').on('click',function(){
    let id = $(this).attr('id');
    window.location =$('#'+id).attr('href');
})
  $('.search-icon-down').click(function() {
  $(".box-search").addClass("fullbox");

  if ($('.box-search').is(':hidden')) {
      $('.box-search').show();

      $('.navbar').addClass('navbar-other');
  }

  else {
    $('.box-search').hide();
    $('.navbar').removeClass('navbar-other');
  }

  });

    $('.search-cross').click(function() {
    $('.box-search').hide();
    $('.navbar').removeClass('navbar-other');
    });


/***************************** */
  $('.grey-heart').click(function() {
      $(this).addClass('added-fav');
  });


//************************************************ */
if (screen.width <= 991) {
    $(".filtros").html("");
}
$(window).scroll(function () {
    if ($(window).scrollTop() > 75) {
        $(".navbar").addClass("navbar-fixed");
    }
    if ($(window).scrollTop() < 75) {
        $(".navbar").removeClass("navbar-fixed");
    }
});

    if ($('.storepage').attr('id')=='homepage') {
    } else {
        $(".login-right > li > .user").removeClass("user");
        $(".navbar").removeClass("navbar-light ");
        $(".navbar").addClass("navbar-other logoheader");
    }
    if ($('.storepage').attr('id')=='homepage') {
        $(".dropdown").hover(
            function () {
                $(".navbar").addClass("navbar-other");
            },
            function () {
                $(".navbar").removeClass("navbar-other");
            }
        );
    }

    $(".minicart").hover(
        function () {
            $("#popover").show();
        },
        function () {
            $("#popover").hide();
        }
    );






//************************************************ */


    $('.navbar-toggler-icon').click(function() {
    $(".navbar-brand ,.mobile-cross").addClass("logo-color");
      });


     $('.moble-cross-icon').click(function() {
        $('.collapse.show').hide();
      });


     $('.menu-button').click(function() {
     $('.addnew-side').addClass('crosstoggles', 5000);
     $('body').addClass('overflow-hidescroll');

       });

     $('.cross-menu').click(function() {
     $('.addnew-side').removeClass('crosstoggles');
     $(".navbar-brand ,.mobile-cross").removeClass("logo-color");
     $('body').removeClass('overflow-hidescroll');
       });


      // $('.newslatter_submit_button').click(function() {
      //   console.log('kdkd')
      //   dataLayer.push({
      //   'event': 'newsletter_sign'
      //   });
      // });
      $('.newslatter_submit_button').on('click', function (e) {
        e.preventDefault();
        var url = $(this).data('href');
        var button = $(this);
        var emailId = $('input[name=hpEmailSignUp]').val();
        $.spinner().start();
        $(this).attr('disabled', true);
        $.ajax({
            url: url,
            type: 'post',
            dataType: 'json',
            data: {
                emailId: emailId
            },
            success: function (data) {
                displayMessage(data, button);
            },
            error: function (err) {
                displayMessage(err, button);
            }
        });
    });
    function displayMessage(data, button) {
    $.spinner().stop();
    var status;

    if (data.success) {
        status = 'alert-success';
    } else {
        status = 'alert-danger';
    }

    dataLayer.push({
        'event': 'newsletter_sign',
        'newsletter_status': data.customerObject.newsletter_status,
        'newsletter_suscribe': data.customerObject.newsletter_suscribe,
        'suscription_date': data.customerObject.suscription_date,
        'user_id': data.customerObject.user_id,
        'register_date': data.customerObject.register_date,
        'cp': data.customerObject.cp,
        'ciudad': data.customerObject.ciudad,
        'estado': data.customerObject.estado,
        'colonia': data.customerObject.colonia,
        'birthday': data.customerObject.birthday,
        'total_purchases': data.customerObject.total_purchases,
        'total_spent': data.customerObject.total_spent,
        'first_purchase_date': data.customerObject.first_purchase_date,
        'last_purchase_date': data.customerObject.last_purchase_date,
        'name_customer': data.customerObject.name_customer,
        'cart_id': data.customerObject.cart_id,
        'date_created_cart': data.customerObject.date_created_cart,
        'date_update_cart': data.customerObject.date_update_cart,
        'total_quantity_cart': data.customerObject.total_quantity_cart,
        'total_price_cart': data.customerObject.total_price_cart,
        'logged_in': data.customerObject.logged_in,
    });

    if ($('.email-signup-message').length === 0) {
        $('body').append(
           '<div class="email-signup-message"></div>'
        );
    }
    if (data.msg) {
        if (data.error && JSON.parse(data.msg).message === 'The event data contains duplicate value for an existing primary key. Please correct the event data and try again.') {
            $('.email-signup-message')
            .append('<div class="email-signup-alert text-center ' + status + '">' + 'Este correo electrónico ya está suscrito.' + '</div>');
        } else {
            $('.email-signup-message')
            .append('<div class="email-signup-alert text-center ' + status + '">' + data.msg + '</div>');
        }
    }

    setTimeout(function () {
        $('.email-signup-message').remove();
        button.removeAttr('disabled');
    }, 3000);
}

/****************************** */

       $('.owl-carousel').owlCarousel({
           margin:5,
           autoplayTimeout:3000,
           autoplayHoverPause:false,
           autoWidth:false,
           nav:false,
           loop:true,
           dots:true,
           responsive:{
               0:{
                   items:2,
                   nav:true
               },
               600:{
                   items:2,
                   nav:false
               },
               1000:{
                   items:2,
                   nav:true,
                   loop:false
               }
           }
       })
